import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { PreferenceService } from "../../services/preference.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-pricing-prefs")
@Component({
    selector: "s25-pricing-prefs",
    template: `@if (this.init) {
        <div>
            <label class="ngBold c-margin-bottom--quarter"> Pricing Times </label>
            <p class="ngFinePrint c-margin-bottom--half">
                Occurrence durations in your pricing formulas can be calculated using the start and end times of an
                event or using the start and end times of the entire reservation (including setup and takedown, as well
                as pre- and post-event times).
            </p>
            <div id="pricingTimes">
                <label for="pricingTimes" class="c-margin-bottom--quarter">Occurrence durations based on:</label>
                <div>
                    <input
                        type="radio"
                        (click)="this.save('RSRV')"
                        [(ngModel)]="this.model.data['OccPriceType'].value"
                        name="pricingTimes"
                        class="s25-input"
                        id="completeEvent"
                        value="RSRV"
                        [checked]="this.model.data['OccPriceType'].value === 'RSRV'"
                    />
                    Reservation Duration (including setup, takedown, and pre-/post-event time)
                </div>
                <div>
                    <input
                        type="radio"
                        (click)="this.save('EVENT')"
                        [(ngModel)]="this.model.data['OccPriceType'].value"
                        name="pricingTimes"
                        class="s25-input"
                        id="minimalEvent"
                        value="EVENT"
                        [checked]="this.model.data['OccPriceType'].value === 'EVENT'"
                    />
                    Event Duration (excluding setup, takedown, and pre-/post-event time)
                </div>
            </div>
        </div>
    }`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingPrefsComponent implements OnInit {
    model: any = {};
    init = false;
    loading = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.getPrefs();
    }

    getPrefs() {
        PreferenceService.getPreferences(["OccPriceType"]).then((data) => {
            this.model.data = data;
            this.model.initData = S25Util.deepCopy(this.model.data);
            this.init = true;
            this.cd.detectChanges();
        });
    }

    save(prefValue: string) {
        this.loading = true;
        this.cd.detectChanges();
        PreferenceService.setPreference("OccPriceType", prefValue, "S").then((resp) => {
            this.loading = false;
            this.cd.detectChanges();
        });
    }
}
