import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { Bind } from "../../../../decorators/bind.decorator";
import { Table } from "../../../s25-table/Table";
import { S25CheckboxComponent } from "../../../s25-checkbox/s25.checkbox.component";
import { GenericTableButtonComponent } from "../../../s25-table/generics/generic.table.button.component";
import { CacheRepository } from "../../../../decorators/cache.decorator";
import { PricingService } from "../../../../services/pricing.service";
import { MasterDefinitionService } from "../../../../services/master.definitions/master.definition.service";
import { S25MasterDefinitionUsageReportComponent } from "../../../master-definitions/s25.master.defs.usage.report.component";
import { S25MasterDefinitionsUsageComponent } from "../../../master-definitions/s25-master-definitions-usage/s25.master.definitions.usage.component";
import { S25TableComponent } from "../../../s25-table/s25.table.component";
import { S25Util } from "../../../../util/s25-util";
import { S25ModalComponent } from "../../../s25-modal/s25.modal.component";
import { S25LoadingApi } from "../../../s25-loading/loading.api";

@TypeManagerDecorator("s25-ng-rate-groups-list")
@Component({
    selector: "s25-ng-rate-groups-list",
    template: `@if (init) {
        <h2 class="c-margin-bottom--single">Rate Groups</h2>
        <div class="c-margin-bottom--single">
            <button class="aw-button aw-button--primary" (click)="add()">Add Rate Group</button>
        </div>
        <s25-ng-table
            [dataSource]="tableConfig"
            [hasFilter]="true"
            [hasRefresh]="true"
            [rowSortable]="true"
            [align]="'center'"
            [sortOrderPreference]="'rate_group'"
        ></s25-ng-table>
        <s25-ng-modal #rateGroupModal [title]="'Rate Group'" [size]="'xs'">
            <div>
                <label for="group-name" class="c-margin-bottom--quarter"> Name<span class="required">*</span> </label>
                <input
                    type="text"
                    class="c-input"
                    id="group-name"
                    [ngClass]="{ 'error-outline': errorMsg, 'c-margin-bottom--single': !errorMsg }"
                    [(ngModel)]="name"
                />
                @if (errorMsg) {
                    <p class="error-message">{{ errorMsg }}</p>
                }
                <label for="org-code-name" class="c-margin-bottom--quarter"> Group Code </label>
                <input type="text" class="c-input c-margin-bottom--single" id="org-code-name" [(ngModel)]="orgCode" />
                <button
                    class="aw-button aw-button--primary add-edit-button"
                    (click)="addEdit()"
                    [disabled]="isCreating"
                >
                    <s25-loading-inline [model]="{}"></s25-loading-inline>
                    {{ isCreating ? "" : "Save" }}
                </button>
            </div>
        </s25-ng-modal>
    }`,
    styles: `
        :host ::ng-deep .s25ngTable {
            max-width: unset;
        }

        :host ::ng-deep .s25-text {
            display: none !important;
        }

        :host ::ng-deep .c-loading__inner {
            stroke: #fff !important;
        }

        :host ::ng-deep .s25-modal--body > div {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            width: fit-content;
            padding: 1em;
        }

        :host ::ng-deep .error-message {
            font-size: 0.9em;
            color: red;
            margin: 5px 0;
        }

        :host ::ng-deep .error-outline {
            outline: 1px solid red;
            outline-offset: 2px;
        }

        :host ::ng-deep .required {
            margin-left: 2px;
        }

        .add-edit-button {
            margin-bottom: 3em;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25RateGroupsListComponent implements OnInit {
    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;
    @ViewChild("rateGroupModal") rateGroupModal: S25ModalComponent;
    @ViewChild("rateGroupModal", { read: ElementRef }) rateGroupModalRef: ElementRef;

    init: boolean;
    tableConfig: Table.DataSource;
    columns: Table.Column[] = [
        { id: "id", header: "ID" },
        { id: "name", header: "Name" },
        { id: "code", header: "Group Code" },
        { id: "active", header: "Active" },
        { id: "usage", header: "Usage", width: "min-content" },
        { id: "report", header: "Report", width: "min-content" },
        GenericTableButtonComponent.Column("Edit", this.edit, "outline"),
        GenericTableButtonComponent.Column("Delete", this.delete, "danger--outline"),
    ];
    isCreating: boolean;
    name: string = "";
    orgCode: string = "";
    isEdit: boolean;
    rowId: number;
    errorMsg: string;

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.initTableConfig();
        this.init = true;
    }

    initTableConfig() {
        this.tableConfig = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: this.columns,
        };
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) {
            CacheRepository.invalidateByService("PricingService", "getRatesAndTaxes");
        }
        const data = await PricingService.getRatesAndTaxes();
        const rates = data.root.rate_groups.rate_group;

        return {
            rows: rates?.map((rate) => {
                return {
                    id: rate.rate_group_id,
                    name: rate.rate_group_name,
                    cells: {
                        id: { text: rate.rate_group_id },
                        name: { text: rate.rate_group_name },
                        code: { text: rate.organization_code || "" },
                        active: {
                            component: S25CheckboxComponent,
                            inputs: {
                                modelValue: rate.defn_state === 1,
                                ariaLabel: `Set ${rate.rate_group_name} as Active or Inactive`,
                                noLabel: true,
                            },
                            outputs: {
                                modelValueChange: async (isActive: boolean) => {
                                    await MasterDefinitionService.setActive("rate_group", rate.rate_group_id, isActive);
                                    rate.defn_state = isActive ? 1 : 0;
                                },
                            },
                        },
                        usage: {
                            component: S25MasterDefinitionsUsageComponent,
                            inputs: {
                                type: "rate_group",
                                itemId: rate.rate_group_id,
                            },
                        },
                        report: {
                            component: S25MasterDefinitionUsageReportComponent,
                            inputs: { type: "group", itemId: rate.rate_group_id },
                        },
                    },
                };
            }),
        };
    }

    @Bind
    async delete(row: Table.Row, instance: GenericTableButtonComponent) {
        instance.isLoading = true;

        try {
            await MasterDefinitionService.delete("rate_group", row.id as number);
            this.initTableConfig();
            await this.tableComponent.refresh(true);
        } catch (e) {
            S25Util.showError(e);
        }

        instance.isLoading = false;
    }

    @Bind
    async addEdit() {
        S25LoadingApi.init(this.rateGroupModalRef.nativeElement);

        if (!this.name) {
            this.errorMsg = "Please enter a name for the rate group.";
            this.isCreating = false;
            this.cd.detectChanges();
            S25LoadingApi.destroy(this.rateGroupModalRef.nativeElement);
            return;
        }

        this.errorMsg = "";
        this.isCreating = true;
        this.cd.detectChanges();

        try {
            let data;
            if (!this.isEdit) {
                data = await MasterDefinitionService.createNew("rate_group", { organization_code: this.orgCode });
            } else {
                await MasterDefinitionService.setGroupCode("rate_group", this.rowId, this.orgCode);
            }

            await MasterDefinitionService.setName(
                "rate_group",
                this.isEdit ? this.rowId : data.rate_group_id,
                this.name,
            );

            S25LoadingApi.destroy(this.rateGroupModalRef.nativeElement);
            await this.rateGroupModal.close();

            this.initTableConfig();
            await this.tableComponent.refresh(true);

            this.name = "";
            this.orgCode = "";
            this.isEdit = false;
        } catch (e) {
            S25LoadingApi.destroy(this.rateGroupModalRef.nativeElement);
            S25Util.showError(e);
        }
        this.isCreating = false;
        this.cd.detectChanges();
    }

    @Bind
    async edit(row: Table.Row) {
        this.name = row.name;
        this.orgCode = row.cells.code.text as string;
        this.isEdit = true;
        this.rowId = row.id as number;

        this.cd.detectChanges();

        await this.rateGroupModal.open();
    }

    async add() {
        this.name = "";
        this.orgCode = "";
        this.isEdit = false;
        this.rowId = null;

        this.cd.detectChanges();

        await this.rateGroupModal.open();
    }
}
