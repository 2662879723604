import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { RateScheduleI, RateTypeObj } from "../../../../pojo/RateScheduleI";
import { S25PricingSheetsComponent } from "./s25.pricing.sheets.component";
import { RateService } from "../../../../services/rate.service";
import { S25ItemI } from "../../../../pojo/S25ItemI";
import { TaxItemI } from "../../../../pojo/Pricing";
import { StateService } from "../../../../services/state.service";
import { S25Util } from "../../../../util/s25-util";
import { S25LoadingApi } from "../../../s25-loading/loading.api";
import { LockService } from "../../../../services/lock.service";
import { TelemetryService } from "../../../../services/telemetry.service";

declare var angular: any;

@TypeManagerDecorator("s25-ng-rate-schedules")
@Component({
    selector: "s25-ng-rate-schedules",
    template: `@if (init) {
        <div class="pricingSchedules">
            <h2 class="c-margin-bottom--single">{{ action + " Rate Schedule" }}</h2>
            <section class="rate-data">
                <label class="rate-data-labels">
                    Rate Type
                    <select
                        class="cn-form__control{{ errorObj.rateType ? ' error' : '' }}"
                        [(ngModel)]="rateType.itemId"
                        (ngModelChange)="updateRateType()"
                    >
                        @if (!rateType.itemId) {
                            <option value="null">Select a Type</option>
                        }
                        <option value="1">Event Types</option>
                        <option value="2">Requirements</option>
                        <option value="3">Locations</option>
                        <option value="4">Resources</option>
                    </select>
                    @if (errorObj.rateType) {
                        <div class="ngRed ngBold">{{ errorObj.rateType }}</div>
                    }
                </label>
                <label class="rate-data-labels">
                    Rate Schedule Name
                    <input
                        class="c-input rate-data-inputs{{ errorObj.rateName ? ' error' : '' }}"
                        [(ngModel)]="rateSchedule.itemName"
                    />
                    @if (errorObj.rateName) {
                        <div class="ngRed ngBold">{{ errorObj.rateName }}</div>
                    }
                </label>
                <label class="rate-data-labels"
                    >Credit Account Code
                    <input
                        class="c-input rate-data-inputs{{ errorObj.credit ? ' error' : '' }}"
                        [class.error]="errorObj.credit"
                        [(ngModel)]="rateSchedule.creditAcctCode"
                    />
                    @if (errorObj.credit) {
                        <div class="ngRed ngBold">{{ errorObj.credit }}</div>
                    }
                </label>
                <label class="rate-data-labels">
                    Debit Account Code
                    <input
                        class="c-input rate-data-inputs{{ errorObj.debit ? ' error' : '' }}"
                        [class.error]="errorObj.debit"
                        [(ngModel)]="rateSchedule.debitAcctCode"
                    />
                    @if (errorObj.debit) {
                        <div class="ngRed ngBold">{{ errorObj.debit }}</div>
                    }
                </label>
            </section>
            <section>
                <s25-simple-collapse [headerText]="'Billable Items'">
                    @if (!rateType.itemId) {
                        <p class="no-rate-type">Please select a <b>Rate Type</b> to add items</p>
                    }
                    @if (rateTypeSelected && showBillingItems) {
                        <s25-ng-multiselect-search-criteria
                            [type]="rateType.itemTypeCode"
                            [selectedItems]="rateSchedule.billingItems"
                            [usePopover]="useBillingPopover"
                            [modelBean]="{ showResult: true }"
                            (onDone)="updateMultiselects('billingItems', $event)"
                        ></s25-ng-multiselect-search-criteria>
                    }
                </s25-simple-collapse>
            </section>
            <section>
                <s25-simple-collapse [headerText]="'Tax Schedules'">
                    @if (showTaxes) {
                        <s25-ng-multiselect-search-criteria
                            [type]="'taxSchedules'"
                            [selectedItems]="rateSchedule.taxes"
                            [usePopover]="useTaxPopover"
                            [modelBean]="{ showResult: true }"
                            (onDone)="updateMultiselects('taxes', $event)"
                        ></s25-ng-multiselect-search-criteria>
                    }
                </s25-simple-collapse>
            </section>
            <section class="price-sheets">
                <s25-simple-collapse [headerText]="'Price Sheets and Formulas'">
                    <s25-ng-pricing-sheets
                        [pricingSheets]="rateSchedule.priceSheets"
                        [isEdit]="action === 'Edit'"
                    ></s25-ng-pricing-sheets>
                </s25-simple-collapse>
            </section>
            <button
                class="aw-button aw-button--primary c-margin-right--half save-schedule-button"
                (click)="save()"
                [disabled]="isSaving"
            >
                <s25-loading-inline [model]="{}"></s25-loading-inline>
                {{ isSaving ? "" : "Save" }}
            </button>
            <button class="aw-button aw-button--outline" (click)="reset()">Cancel</button>
        </div>
    }`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25RateSchedulesComponent implements OnInit {
    @Input() rateSchedule?: RateScheduleI;
    @Input() action: "Copy" | "Edit" | "Create" = "Create";

    @Output() cancelEdit: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(S25PricingSheetsComponent) pricingSheetsComp: S25PricingSheetsComponent;

    init: boolean;
    rateType: RateTypeObj;
    rateTypeSelected: boolean;
    showTaxes: boolean = true;
    showBillingItems: boolean = true;
    useTaxPopover: boolean;
    useBillingPopover: boolean;
    origTax: TaxItemI[];
    origBilling: S25ItemI[];
    isSaving: boolean;
    errorObj: RateScheduleErrorObj = {
        rateDataErrors: false,
        priceSheetErrors: false,
        rateType: null,
        rateName: null,
        pricingSheets: null,
        credit: null,
        debit: null,
    };

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    async ngOnInit() {
        if (this.action === "Create") {
            this.rateSchedule = {
                billingItems: [],
                creditAcctCode: "",
                debitAcctCode: "",
                favorite: false,
                itemName: "",
                priceSheets: [],
                status: "new",
                taxes: [],
            };

            this.rateType = { itemId: null, itemTypeName: null, itemTypeCode: null };
        } else {
            const rateGroups = await RateService.getRateGroups();

            const groupSortOrder: { [key: number]: number } = {};
            rateGroups?.forEach((group) => {
                groupSortOrder[group.rate_group_id] = group.sort_order;
            });

            this.rateSchedule.priceSheets?.sort(
                (a, b) => groupSortOrder[a.rateGroupId] - groupSortOrder[b.rateGroupId],
            );

            if (this.action === "Copy") {
                this.rateSchedule.itemId = null;
                this.rateSchedule.itemName += " (copy)";
                S25Util.setOnObjectDeep(this.rateSchedule, "status", "new", [], true);
            } else {
                this.origTax = [...this.rateSchedule.taxes];
                this.origBilling = [...this.rateSchedule.billingItems];
            }

            this.rateType = RateService.getItemTypeName(this.rateSchedule.itemTypeId);
            this.rateTypeSelected = !!this.rateType.itemTypeCode;
        }

        this.useBillingPopover = this.rateSchedule.billingItems.length > 0;
        this.useTaxPopover = this.rateSchedule.taxes.length > 0;

        this.init = true;
        this.cd.detectChanges();
    }

    updateRateType() {
        this.rateTypeSelected = false;
        this.rateSchedule.billingItems = [];
        this.cd.detectChanges();

        this.rateTypeSelected = true;
        this.rateType = RateService.getItemTypeName(this.rateType.itemId);
        this.rateSchedule.itemTypeId = this.rateType.itemId;
        this.rateSchedule.itemTypeName = this.rateType.itemTypeName;
        this.cd.detectChanges();
    }

    async save() {
        if (this.validate()) {
            this.isSaving = true;
            this.cd.detectChanges();
            S25LoadingApi.init(this.elementRef.nativeElement);

            if (this.action === "Edit") this.updateBillingTaxStatus();

            this.action !== "Create" && (await LockService.unlock(101, this.rateSchedule.itemId));

            TelemetryService.sendWithSub("Pricing", "Settings", "RateSchedule" + this.action);
            return RateService.setRateSchedule(this.rateSchedule.itemId, this.rateSchedule)
                .then((data) => {
                    S25LoadingApi.destroy(this.elementRef.nativeElement);
                    this.isSaving = false;
                    this.cd.detectChanges();
                    //allow time for new rate schedule to be set in db before reroute to list to allow for list display
                    return StateService.go("editPricingFormulas", null, null)
                        .then(() => {
                            return angular
                                .element(document.body)
                                .injector()
                                .get("$rootScope")
                                .$broadcast("pricing-created-schedule");
                        })
                        .then(() => {
                            const saveMsg = data?.results?.info?.msg_id;
                            if (saveMsg === "RT_I_SAVE" || saveMsg === "RT_I_CREATED") this.reset();
                        });
                })
                .catch((e) => {
                    S25LoadingApi.destroy(this.elementRef.nativeElement);
                    const error = e.error.results.error.proc_error;

                    if (error === "Rate name already used.  Please enter a unique rate name.") {
                        this.errorObj.rateName = error;
                        this.errorObj.rateDataErrors = true;
                    } else if (error === "Please specify a valid formula") {
                        this.pricingSheetsComp.invalidFormulaError = true;
                        this.pricingSheetsComp.detectChanges();
                        this.errorObj.priceSheetErrors = true;
                    } else {
                        S25Util.showError(e);
                    }

                    this.isSaving = false;
                    this.cd.detectChanges();
                    this.handleErrorScroll();
                });
        } else {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
            this.isSaving = false;
            this.cd.detectChanges();
            this.handleErrorScroll();
        }
    }

    updateBillingTaxStatus() {
        this.origTax.forEach((tax) => {
            if (!this.rateSchedule.taxes.includes(tax)) {
                tax.status = "del";
                this.rateSchedule.taxes.push(tax);
            } else {
                const index = this.rateSchedule.taxes.indexOf(tax);
                this.rateSchedule.taxes[index].status = "est";
            }
        });

        this.rateSchedule.taxes.map((tax) => {
            tax.status ??= "new";
            return tax;
        });

        this.origBilling.forEach((item) => {
            if (!this.rateSchedule.billingItems.includes(item)) {
                item.status = "del";
                this.rateSchedule.billingItems.push(item);
            } else {
                const index = this.rateSchedule.billingItems.indexOf(item);
                this.rateSchedule.billingItems[index].status = "est";
            }
        });

        this.rateSchedule.billingItems.map((item) => {
            item.status ??= "new";
            return item;
        });
    }

    reset() {
        if (this.action === "Create") {
            this.rateSchedule = null;
            this.rateTypeSelected = false;
            this.init = false;

            this.cd.detectChanges();

            return this.ngOnInit();
        } else {
            const scheduleId = this.rateSchedule.itemId;

            this.rateSchedule = null;
            this.rateTypeSelected = false;
            this.init = false;

            this.cd.detectChanges();
            this.cancelEdit.emit();

            return LockService.unlock(101, scheduleId);
        }
    }

    updateMultiselects(multiselect: "taxes" | "billingItems", items: S25ItemI[] | TaxItemI[]) {
        const capitalizedProp: "Taxes" | "BillingItems" = (multiselect[0].toUpperCase() + multiselect.slice(1)) as
            | "Taxes"
            | "BillingItems";
        const prop: "showBillingItems" | "showTaxes" = `show${capitalizedProp}`;

        this[prop] = false;
        this.cd.detectChanges();

        this.rateSchedule[multiselect] = items;

        this[prop] = true;

        if (multiselect === "taxes") {
            this.useTaxPopover = this.rateSchedule.taxes.length > 0;
        } else {
            this.useBillingPopover = this.rateSchedule.billingItems.length > 0;
        }

        this.cd.detectChanges();
    }

    validate() {
        let dataError = false;

        if (!this.rateType.itemId) {
            this.errorObj.rateType = "Please select a Rate Type";
            dataError = true;
        } else {
            this.errorObj.rateType = null;
        }

        if (!this.rateSchedule.itemName) {
            this.errorObj.rateName = "Please enter a Rate Schedule Name";
            dataError = true;
        } else if (this.rateSchedule.itemName.length > 40) {
            this.errorObj.rateName = "Rate Schedule Name must not exceed 40 characters";
            dataError = true;
        } else {
            this.errorObj.rateName = null;
        }

        if (this.rateSchedule.creditAcctCode.length > 60) {
            this.errorObj.credit = "Credit Account Code must not exceed 60 characters";
            dataError = true;
        } else {
            this.errorObj.credit = null;
        }

        if (this.rateSchedule.debitAcctCode.length > 60) {
            this.errorObj.debit = "Debit Account Code must not exceed 60 characters";
            dataError = true;
        } else {
            this.errorObj.debit = null;
        }

        if (dataError) {
            this.errorObj.rateDataErrors = dataError;
            this.cd.detectChanges();
            return false;
        }

        this.errorObj.rateDataErrors = false;

        const pricingSheetsError = this.pricingSheetsComp.validatePricingSheets(this.errorObj);

        if (pricingSheetsError) return false;

        this.errorObj.priceSheetErrors = false;
        this.cd.detectChanges();
        return true;
    }

    handleErrorScroll() {
        if (this.errorObj.rateDataErrors) {
            const rateData = this.elementRef.nativeElement.querySelector(".rate-data");
            rateData?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
        } else if (this.errorObj.priceSheetErrors) {
            const priceSheets = this.elementRef.nativeElement.querySelector("#pricing-sheets--table");
            priceSheets?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
        }
    }
}

export type RateScheduleErrorObj = {
    rateDataErrors: boolean;
    priceSheetErrors: boolean;
    rateType: string | null;
    rateName: string | null;
    pricingSheets: string | null;
    credit: string | null;
    debit: string | null;
};
