import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    QueryList,
    ViewChildren,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { DropDownItem } from "../../../../pojo/DropDownItem";
import { S25Util } from "../../../../util/s25-util";
import { FormulaI, PriceSheetI } from "../../../../pojo/RateScheduleI";
import { FormulaErrorObj, S25FormulaBuilderComponent } from "./s25-formula-builder/s25.formula.builder.component";
import { RateScheduleErrorObj } from "./s25.rate.schedules.component";
import { DurationI } from "../../../../pojo/DurationI";

@TypeManagerDecorator("s25-ng-pricing-sheets")
@Component({
    selector: "s25-ng-pricing-sheets",
    template: ` @if (init) {
        <div class="simpleCollapseContent">
            <div>
                Filter price sheets by rate group. Each Rate Group can have multiple Price Sheets, each of which should
                have one or more Rate Formulas.
            </div>
            <label class="c-margin-top--half">
                Rate Group
                <s25-rate-group-dropdown
                    [chosen]="selectedRateGroup"
                    [staticItems]="[selectedRateGroup]"
                    (chosenChange)="filterRateGroups($event)"
                ></s25-rate-group-dropdown>
            </label>
            @if (invalidFormulaError) {
                <div class="ngRed ngBold c-margin-top--half">Please ensure that all Rate Formulas are valid</div>
            }
            <table class="b-listview c-margin-bottom--single" id="pricing-sheets--table" [class.pivoted]="isPivoted">
                <thead class="ngTableHeader">
                    <tr>
                        <th class="b-listview-th">Rate Group</th>
                        <th class="b-listview-th">Effective Date</th>
                        <th class="b-listview-th">Description</th>
                    </tr>
                </thead>
                <tbody>
                    @if (showNoSheetsAlert) {
                        <tr>
                            <td colspan="3" class="no-pricing-sheets">No Matching Pricing Sheets</td>
                        </tr>
                    }
                    @for (sheet of pricingSheets; track sheet; let i = $index) {
                        @if (
                            sheet.status !== "del" &&
                            (selectedRateGroup.itemId === -1 || selectedRateGroup.itemId === +sheet.rateGroupId)
                        ) {
                            <tr
                                class="pricingSheetRow"
                                id="sheet-row-{{ i + 1 }}"
                                [ngClass]="{ expanded: formulaRowMap[i] }"
                            >
                                <td data-label="Rate Group Name">
                                    @if (formulaRowMap[i]) {
                                        <svg class="c-svgIcon" role="img" tabindex="0" (click)="toggleRow(i)">
                                            <title>Collapse Formula Data</title>
                                            <use
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#caret--caret-down"
                                            ></use>
                                        </svg>
                                    }
                                    @if (sheet.rateGroupName && !formulaRowMap[i]) {
                                        <svg class="c-svgIcon" role="img" tabindex="0" (click)="toggleRow(i)">
                                            <title>Expand Formula Data</title>
                                            <use
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#caret--caret-right"
                                            ></use>
                                        </svg>
                                    }
                                    @if (sheet.rateGroupName) {
                                        <div (click)="toggleRow(i)">{{ sheet.rateGroupName }}</div>
                                    }
                                    @if (!sheet.rateGroupName) {
                                        <s25-rate-group-dropdown
                                            (chosenChange)="selectRateGroup($event, sheet, i)"
                                        ></s25-rate-group-dropdown>
                                    }
                                    @if (errorMap[i]) {
                                        <div class="ngRed ngBold rate-group-error">
                                            Please choose a rate group for every pricing sheet
                                        </div>
                                    }
                                </td>
                                <td data-label="Effective Date">
                                    <s25-datepicker
                                        [(modelValue)]="sheet.datepickerBean"
                                        (modelValueChange)="updateDate($event, sheet)"
                                    ></s25-datepicker>
                                </td>
                                <td data-label="Description">
                                    <textarea
                                        [(ngModel)]="sheet.description.data"
                                        (ngModelChange)="setDescription(sheet)"
                                        [attr.aria-label]="
                                            sheet.rateGroupName
                                                ? 'Description for ' + sheet.rateGroupName
                                                : 'Rate Group Description'
                                        "
                                        class="cn-form__control"
                                    ></textarea>
                                    @if (!isPivoted) {
                                        <svg class="c-svgIcon delete-icon" role="img" (click)="deleteSheet(sheet, i)">
                                            <title>Delete Pricing Sheet</title>
                                            <use
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#minus"
                                            ></use>
                                        </svg>
                                    }
                                </td>
                                @if (formulaRowMap[i]) {
                                    <td data-label="Rate Formulas" colspan="3">
                                        <s25-ng-formula-builder
                                            [formulas]="sheet.formula"
                                            [isEdit]="isEdit"
                                            [sheetIndex]="i"
                                            [validateFunc]="validateFormula"
                                        ></s25-ng-formula-builder>
                                    </td>
                                }
                                @if (isPivoted) {
                                    <td class="delete-sheet-btn">
                                        <button
                                            class="aw-button aw-button--danger--outline"
                                            (click)="deleteSheet(sheet, i)"
                                        >
                                            Delete Pricing Sheet
                                        </button>
                                    </td>
                                }
                            </tr>
                        }
                    }
                </tbody>
            </table>
            <button class="aw-button aw-button--primary" (click)="addSheet()">Add Pricing Sheet</button>
        </div>
    }`,
    styles: [
        `
            :host ::ng-deep .b-listview td {
                border-width: unset;
                border-color: #eee;
            }

            :host ::ng-deep .expanded td {
                border: none;
            }

            :host ::ng-deep .ngRed.ngBold:not(.rate-group-error) {
                text-align: center;
                border: 1px solid #b00303;
                padding: 1em;
                border-radius: 5px;
                border-left-width: 4px;
                width: 95%;
                margin: auto;
            }

            .delete-icon {
                background-color: red;
                color: #fff;
                border-radius: 50%;
            }

            :host ::ng-deep .c-svgIcon:hover,
            td[data-label="Rate Group Name"] > div:hover {
                cursor: pointer;
            }

            td[data-label="Description"] {
                display: flex;
                align-items: center;
                gap: 1em;
            }

            td[data-label="Rate Group Name"] {
                display: flex;
                flex-wrap: wrap;
                gap: 0.25em;
            }

            td[data-label="Rate Formulas"] {
                grid-column: 1 / span 3;
                padding: 0 !important;
            }

            .no-pricing-sheets {
                color: #6c6c6c;
                font-size: 1.333rem;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingSheetsComponent implements OnInit {
    @Input() pricingSheets: PriceSheetI[];
    @Input() isEdit: boolean = false;

    @ViewChildren(S25FormulaBuilderComponent) formulaBuilders: QueryList<S25FormulaBuilderComponent>;

    init: boolean;
    selectedRateGroup: DropDownItem = { itemId: -1, itemName: "All" };
    showNoSheetsAlert: boolean;
    formulaRowMap: { [key: number]: boolean } = {};
    formulaErrorObj: FormulaErrorObj;
    errorMap: { [key: number]: boolean } = {};
    invalidFormulaError: boolean;
    isPivoted: boolean;

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    ngOnInit() {
        if (this.pricingSheets.length === 0) {
            this.addSheet();
        } else {
            this.pricingSheets.forEach((sheet: PriceSheetI) => {
                sheet.formula.sort(S25Util.shallowSort("break_point"));
                sheet.datepickerBean = { date: S25Util.date.parse(sheet.startDt) };
            });
        }

        this.isPivoted = window.innerWidth <= 1290;

        this.init = true;
        this.cd.detectChanges();
    }

    @HostListener("window:resize")
    onWindowResize() {
        this.isPivoted = window.innerWidth <= 1290;
        this.cd.detectChanges();
    }

    addSheet() {
        const groupId = (this.selectedRateGroup.itemId as number) > 0 ? this.selectedRateGroup.itemId : null;
        const groupName = groupId ? this.selectedRateGroup.itemName : "";

        const newPricingSheet: PriceSheetI = {
            version: 0, //zero is a placeholder RateService will set version to the correct value before the PUT.
            status: "new",
            startDt: new Date(),
            rateGroupId: groupId as number,
            rateGroupName: groupName,
            rateGroup: groupId ? { itemName: groupName, itemId: groupId } : null,
            description: { data: "" },
            datepickerBean: { date: new Date() },
            formula: [
                {
                    status: "new",
                    break_point: "1",
                    rangeType: "none",
                    durStart: "",
                    durEnd: "",
                    expression: "",
                },
            ],
        };

        this.pricingSheets.push(newPricingSheet);
        this.filterRateGroups();
    }

    updateDate(date: Date, pricingSheet: PriceSheetI) {
        pricingSheet.startDt = date;
        pricingSheet.datepickerBean = { date: date };
        pricingSheet.status = S25Util.isStatusChanged(pricingSheet) ? pricingSheet.status : "mod";
    }

    filterRateGroups(rateGroup?: DropDownItem) {
        if (rateGroup) this.selectedRateGroup = { itemId: rateGroup.itemId, itemName: rateGroup.itemName };

        const filteredSheets = this.pricingSheets.filter(
            (sheet) =>
                sheet.status !== "del" &&
                (this.selectedRateGroup.itemId === -1 || this.selectedRateGroup.itemId === +sheet.rateGroupId),
        );

        this.showNoSheetsAlert = filteredSheets.length === 0;
        this.cd.detectChanges();
    }

    selectRateGroup(rateGroup: DropDownItem, pricingSheet: PriceSheetI, rowIndex: number) {
        const { itemId, itemName } = rateGroup;

        pricingSheet.rateGroup = { itemId, itemName };
        pricingSheet.rateGroupId = itemId as number;
        pricingSheet.rateGroupName = itemName;

        this.formulaRowMap[rowIndex] = true;
        this.errorMap[rowIndex] = false;

        this.cd.detectChanges();
    }

    setDescription(pricingSheet: PriceSheetI) {
        pricingSheet.status = S25Util.isStatusChanged(pricingSheet) ? pricingSheet.status : "mod";
    }

    deleteSheet(pricingSheet: PriceSheetI, index: number) {
        if (pricingSheet.status === "new") {
            this.pricingSheets.splice(index, 1);
        } else {
            pricingSheet.status = "del";
            pricingSheet.formula.map((formula) => (formula.status = "del"));
        }

        this.formulaRowMap[index] = false;
        this.filterRateGroups();
    }

    toggleRow(index: number, isError?: boolean) {
        this.formulaRowMap[index] = isError ? true : !this.formulaRowMap[index];

        this.cd.detectChanges();
    }

    validatePricingSheets(errorObj: RateScheduleErrorObj) {
        let error = false;
        errorObj.priceSheetErrors = false;
        this.invalidFormulaError = false;

        this.pricingSheets.some((sheet, sheetIdx) => {
            if (error || !sheet.rateGroupId) {
                error = true;
                errorObj.pricingSheets = !sheet.rateGroupId ? "Please select a Rate Group for all Pricing Sheets" : "";
                this.errorMap[sheetIdx] = !sheet.rateGroupId;
                errorObj.priceSheetErrors = true;
                return true;
            }

            sheet.formula.some((formula, formulaIdx) => {
                const { isValid, errorMap } = this.validateFormula(formula);
                error = !isValid;
                if (error) {
                    errorObj.priceSheetErrors = true;
                    this.handleFormulaError(sheetIdx, errorMap, formulaIdx);
                    return true;
                }
                if (formula.status === "mod" && sheet.status !== "mod") sheet.status = "mod";
                return false;
            });
            return false;
        });

        this.formulaErrorObj &&= null;
        this.cd.detectChanges();
        return error;
    }

    validateFormula(formula: FormulaI) {
        let isValid = true;

        const errorMap: FormulaErrorObj = { timeErrorMsg: "", formulaErrorMsg: "" };

        if (formula.rangeType === "time") {
            isValid =
                +S25Util.date.parse(formula.durStart as string | Date) <
                +S25Util.date.parse(formula.durEnd as string | Date);
            if (!isValid) errorMap.timeErrorMsg = "End time must be after start time";
        } else if (formula.rangeType === "duration") {
            isValid =
                S25Util.ISODurationToMinutes(S25Util.daysHoursMinutesToDuration(formula.durStart as DurationI)) <
                S25Util.ISODurationToMinutes(S25Util.daysHoursMinutesToDuration(formula.durEnd as DurationI));
            if (!isValid) errorMap.timeErrorMsg = "Minimum duration must be smaller than maximum duration";
        }

        if (formula.expression === "") {
            isValid = false;
            errorMap.formulaErrorMsg = "Please enter a formula expression";
        }

        this.cd.detectChanges();
        return { isValid, errorMap };
    }

    detectChanges() {
        this.cd.detectChanges();
    }

    handleFormulaError(sheetIdx: number, errorObj: FormulaErrorObj, formulaIdx: number) {
        const sheetRow = this.elementRef.nativeElement.querySelector(`#sheet-row-${sheetIdx}`);
        sheetRow?.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        this.toggleRow(sheetIdx, true);
        const invalidFormula = this.formulaBuilders.find((formulaBuilder) => formulaBuilder.sheetIndex === sheetIdx);
        invalidFormula?.setErrorMsg(errorObj, formulaIdx);
    }
}
