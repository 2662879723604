import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { Bind } from "../../../../decorators/bind.decorator";
import { Table } from "../../../s25-table/Table";
import { CacheRepository } from "../../../../decorators/cache.decorator";
import { GenericTableButtonComponent } from "../../../s25-table/generics/generic.table.button.component";
import { RateService } from "../../../../services/rate.service";
import { RateSchedule, RateScheduleI, RateTypeObj } from "../../../../pojo/RateScheduleI";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { OptBean } from "../../../s25-opt/s25.opt.component";
import { OptUtil } from "../../../s25-opt/s25.opt.util";
import { S25TableComponent } from "../../../s25-table/s25.table.component";
import { S25ModalComponent } from "../../../s25-modal/s25.modal.component";
import { LockService } from "../../../../services/lock.service";

declare var angular: any;

@TypeManagerDecorator("s25-ng-rate-schedules-list")
@Component({
    selector: "s25-ng-rate-schedules-list",
    template: `<div *ngIf="init" [ngSwitch]="view">
        <ng-container *ngSwitchCase="'list'">
            <h2 class="c-margin-bottom--single">Rate Schedules</h2>
            <select
                class="cn-form__control c-margin-bottom--half"
                [(ngModel)]="rateTypeFilter"
                (ngModelChange)="filterByType()"
                aria-label="Filter by Type"
            >
                <option value="">All</option>
                <option value="Event Types">Event Types</option>
                <option value="Requirements">Requirements</option>
                <option value="Locations">Locations</option>
                <option value="Resources">Resources</option>
            </select>
            <s25-ng-opt [modelBean]="optBean"></s25-ng-opt>
            <s25-ng-table
                [dataSource]="tableConfig"
                [hasFilter]="true"
                [columnSortable]="true"
                [class.hide]="hideTable"
                (onRefresh)="filterByType()"
            ></s25-ng-table>
        </ng-container>
        <ng-container *ngSwitchCase="'copyEdit'">
            <s25-ng-rate-schedules
                [rateSchedule]="actionSchedule"
                [action]="action"
                (cancelEdit)="updateView()"
            ></s25-ng-rate-schedules>
        </ng-container>
        <s25-ng-modal #confirmDeleteModal [title]="'Confirm Delete'" [type]="'deny'" [size]="'sm'">
            Are you sure you want to delete this rate schedule?
        </s25-ng-modal>
    </div>`,
    styles: [
        `
            :host ::ng-deep .ngOptBar {
                max-width: 1200px;
                height: 0;
            }

            :host ::ng-deep .ngOptSubContainer {
                position: relative;
                top: -10px;
            }

            :host ::ng-deep .rose-object-table-header {
                position: relative;
                z-index: 1;
                width: fit-content;
            }

            :host ::ng-deep select:not(.form-select) {
                width: 15em;
            }

            .hide {
                visibility: hidden;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25RateSchedulesListComponent implements OnInit {
    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;
    @ViewChild("confirmDeleteModal") confirmDeleteModal: S25ModalComponent;

    init: boolean;
    tableConfig: Table.DataSource;
    optBean: OptBean;
    rateTypeFilter: RateTypeObj["itemTypeName"] | "" = "";
    tableRows: Table.Row[];
    view: "copyEdit" | "list" = "list";
    actionSchedule: RateScheduleI;
    action: "Edit" | "Copy";
    hideTable: boolean;
    filterValue: string;
    columns: Table.Column[] = [
        { id: "name", header: "Name" },
        { id: "type", header: "Type" },
        GenericTableButtonComponent.Column("Edit", this.edit, "outline"),
        GenericTableButtonComponent.Column("Copy", this.copy, "outline"),
        GenericTableButtonComponent.Column("Delete", this.delete, "danger--outline"),
    ];

    constructor(private cd: ChangeDetectorRef) {}

    async ngOnInit() {
        angular
            .element(document.body)
            .injector()
            .get("$rootScope")
            .$on("pricing-created-schedule", () => this.refresh());

        this.optBean = await OptUtil.formPricingListBean(this.refresh, null);

        this.tableConfig = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: this.columns,
        };

        this.init = true;
        this.cd.detectChanges();
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) {
            CacheRepository.invalidateByService("RateService", "getRateSchedules");
        }

        const data = await RateService.getRateSchedules();
        this.tableRows = [];

        return {
            rows: data?.map((schedule: RateSchedule) => {
                const row = {
                    id: +schedule.rate_id,
                    name: schedule.type,
                    cells: {
                        name: { text: schedule.rate_name },
                        type: { text: schedule.type },
                    },
                };

                this.tableRows.push(row);
                return row;
            }),
        };
    }

    @Bind
    refresh() {
        return this.tableComponent?.refresh(true);
    }

    filterByType(filterValue?: string) {
        filterValue ??= this.tableComponent.filterValue;

        if (this.rateTypeFilter) {
            this.tableComponent.rows = this.tableRows.filter((row) => row.name === this.rateTypeFilter);
        } else {
            this.tableComponent.rows = this.tableRows;
        }

        this.tableComponent.setFilteredRows([...this.tableComponent.rows]);
        return this.tableComponent.applyFilter(filterValue);
    }

    updateView() {
        this.hideTable = true;
        this.view = "list";
        this.cd.detectChanges();

        setTimeout(async () => {
            await this.filterByType(this.filterValue);
            this.hideTable = false;
            this.cd.detectChanges();
        }, 500);
    }

    @Bind
    edit(row: Table.Row) {
        return this.copyEdit("Edit", row);
    }

    @Bind
    copy(row: Table.Row) {
        return this.copyEdit("Copy", row);
    }

    async copyEdit(action: "Edit" | "Copy", row: Table.Row) {
        const lock = await LockService.lock(101, row.id as number);
        if (!lock) {
            alert("This rate schedule is already locked by another User. Remove Locks or try again");
            return;
        }

        this.filterValue = this.tableComponent.filterValue;
        this.actionSchedule = await RateService.getRateScheduleObj(row.id);
        this.action = action;
        this.view = "copyEdit";

        this.cd.detectChanges();
    }

    @Bind
    async delete(row: Table.Row) {
        const yes = await this.confirmDeleteModal.open();

        if (yes) {
            await RateService.deleteRateSchedule(row.id);
            return this.refresh();
        }
    }
}
