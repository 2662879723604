import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { PreferenceService } from "../../../../services/preference.service";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";

@TypeManagerDecorator("s25-accounting-prefs")
@Component({
    selector: "s25-accounting-prefs",
    template: `@if (this.init) {
        <div>
            <div class="c-margin-bottom--single">
                <h2 class="c-margin-bottom--single">Accounting Formats</h2>
                <div class="ngBold c-margin-bottom--quarter">Rate Schedule Account Formats</div>
                <p class="ngFinePrint c-margin-bottom--half">
                    These fields are used to populate the debit and credit account fields for each chargeable item for
                    an event. They are present when you view pricing of an event.
                </p>
                <div class="c-margin-bottom--single c-objectDetails c-objectDetails--borderedSection">
                    <div class="c-sectionHead">
                        <label class="simpleCollapseLabel">Keywords</label>
                    </div>
                    <s25-simple-collapse [titleText]="'Keywords'">
                        <div class="simpleCollapseContent">
                            <p class="c-margin-bottom--half">
                                There are three available keywords for these fields, one or more of which can optionally
                                be used along other text for formatting purposes. Click on a keyword to copy it to your
                                clipboard.
                            </p>
                            <ul>
                                <li class="c-margin-bottom--half">
                                    <a
                                        (click)="copyTextToClipBoard('OrganizationCode')"
                                        aria-label="copy keyword to clipboard"
                                        class="c-textButton tooltipButton c-margin-right--half"
                                        tabindex="0"
                                        title="Copy to Clipboard"
                                        ><strong>OrganizationCode</strong
                                        ><span class="tooltipBefore">Copy to Clipboard</span
                                        ><span class="tooltipAfter">Copied!</span></a
                                    >The organization record account number field.
                                </li>
                                <li class="c-margin-bottom--half">
                                    <a
                                        (click)="copyTextToClipBoard('GroupCode')"
                                        aria-label="copy keyword to clipboard"
                                        class="c-textButton tooltipButton c-margin-right--half"
                                        tabindex="0"
                                        title="Copy to Clipboard"
                                        ><strong>GroupCode</strong><span class="tooltipBefore">Copy to Clipboard</span
                                        ><span class="tooltipAfter">Copied!</span></a
                                    >The Rate Group "Code Group" (example: FP, IN, NP).
                                </li>
                                <li class="c-margin-bottom--half">
                                    <a
                                        (click)="copyTextToClipBoard('AccountNumber')"
                                        aria-label="copy keyword to clipboard"
                                        class="c-textButton tooltipButton c-margin-right--half"
                                        tabindex="0"
                                        title="Copy to Clipboard"
                                        ><strong>AccountNumber</strong
                                        ><span class="tooltipBefore">Copy to Clipboard</span
                                        ><span class="tooltipAfter">Copied!</span></a
                                    >This is two fields: Credit Account Code and Debit Account Code from the rate
                                    schedule.
                                </li>
                            </ul>
                            Example: OrganizationCode[AccountNumber]
                        </div>
                    </s25-simple-collapse>
                </div>
                <div class="c-margin-bottom--single accountFormats">
                    <label for="creditAccountFormat" class="ngBold c-margin-bottom--quarter ngBlock">
                        Credit Account Format
                    </label>
                    <input
                        type="text"
                        [(ngModel)]="this.model.data['RateCreditAcctNum'].value"
                        name="creditAccountFormat"
                        class="s25-input"
                        id="rateCreditAcctNum"
                    />
                </div>
                <div class="c-margin-bottom--single accountFormats">
                    <label for="debitAccountFormat" class="ngBold c-margin-bottom--quarter ngBlock">
                        Debit Account Format
                    </label>
                    <input
                        type="text"
                        [(ngModel)]="this.model.data['RateDebitAcctNum'].value"
                        name="debitAccountFormat"
                        class="s25-input"
                        id="rateDebitAcctNum"
                    />
                </div>
            </div>
            <div class="c-displayBlock c-padding-bottom--double">
                <button
                    class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                    (click)="save()"
                >
                    {{ this.loading ? "Saving...." : "Save" }}
                </button>
                <button class="aw-button aw-button--outline c-margin-top--single" (click)="cancel()">Cancel</button>
            </div>
        </div>
    }`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25AccountingPrefsComponent implements OnInit {
    model: any = {};
    init = false;
    loading = false;
    prefsArray = ["RateCreditAcctNum", "RateDebitAcctNum"];

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.getPrefs();
    }

    getPrefs() {
        PreferenceService.getPreferences(this.prefsArray).then((data) => {
            this.model.data = data;
            this.model.initData = S25Util.deepCopy(this.model.data);
            this.init = true;
            this.cd.detectChanges();
        });
    }

    save() {
        this.loading = true;
        this.cd.detectChanges();

        let promiseArray: any = [];
        this.prefsArray.forEach((name) => {
            if (this.model.data[name].value !== this.model.initData[name].value) {
                promiseArray.push(PreferenceService.setPreference(name, this.model.data[name].value, "S"));
            }
        });

        S25Util.all(promiseArray).then((data) => {
            return S25Util.all(promiseArray).then((resp) => {
                this.loading = false;
                this.cd.detectChanges();
            });
        });
    }

    cancel() {
        this.getPrefs();
    }

    copyTextToClipBoard(text: string) {
        S25Util.copyToClipboard(text);
    }
}
