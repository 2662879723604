import { formatNumber } from "@angular/common";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { DropDownItem } from "../../../../pojo/DropDownItem";
import { TaxesService } from "../../../../services/taxes.service";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-tax-schedules")
@Component({
    selector: "s25-ng-tax-schedules",
    template: `
        @if (init) {
            <div>
                <div class="c-margin-bottom--single pricingTaxSchedules">
                    <label for="taxName" class="ngBold c-margin-right--quarter">Name</label>
                    <input class="s25-input" type="text" id="taxName" [(ngModel)]="model.data.tax_name" required />
                    @if (errMsg.taxName) {
                        <div class="ngRed">{{ errMsg.taxName }}</div>
                    }
                    <div class="ngBold c-margin-top--single c-margin-bottom--quarter ngBlock">Rates</div>
                    <ul class="rates">
                        @for (rate of model.data.rate; track rate) {
                            @if (rate.status != "del") {
                                <li class="rate">
                                    <s25-ng-button [type]="'none'" (click)="removeRate(rate)">
                                        <s25-ng-icon [type]="'close'" />
                                    </s25-ng-button>
                                    <div class="rateOptions">
                                        <s25-datepicker
                                            [inputLabel]="'Start Date'"
                                            [inputId]="'startDate'"
                                            [(modelValue)]="rate.startDateModel"
                                            [inline]="false"
                                            [multipleDate]="false"
                                            (onModelValueChange)="onChange(rate)"
                                        ></s25-datepicker>
                                        <div>
                                            <label
                                                for="tax"
                                                class="ngBold c-margin-right--quarter  c-margin-left--single"
                                            >
                                                Tax Rate
                                            </label>
                                            <s25-ng-editable-percentage
                                                id="tax"
                                                [(val)]="rate.percentage"
                                            ></s25-ng-editable-percentage>
                                        </div>
                                    </div>
                                </li>
                            }
                        }
                    </ul>
                    <button class="aw-button aw-button--primary" (click)="addRate()">New Rate</button>
                    @if (errMsg.rates) {
                        <div class="ngRed">{{ errMsg.rates }}</div>
                    }
                    <label class="ngBold c-margin-top--single c-margin-bottom--quarter ngBlock">Rate Groups</label>
                    <p class="ngFinePrint c-margin-bottom--half">
                        Select Rate Group(s) associated with this tax schedule.
                    </p>
                    <s25-ng-dropdown-rate-group
                        [(chosen)]="newRateGroups"
                        [resetSelectedOnCleanup]="true"
                    ></s25-ng-dropdown-rate-group>
                    <ul>
                        @for (group of newRateGroups; track group; let i = $index) {
                            <li>
                                <s25-ng-button [type]="'none'" (click)="newRateGroups.splice(i, 1)">
                                    <s25-ng-icon [type]="'close'" />
                                </s25-ng-button>
                                <span>{{ group.itemName }}</span>
                            </li>
                        }
                    </ul>
                    <div class="c-displayBlock c-padding-bottom--double c-margin-top--single buttons">
                        <button
                            class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                            (click)="save()"
                        >
                            {{ loading ? "Saving...." : "Save" }}
                        </button>
                        <button
                            class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                            (click)="cancel()"
                        >
                            Cancel
                        </button>
                    </div>
                    @if (message) {
                        <div class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single" role="alert">
                            <div class="cn-alert__icon cn-icon" name="alert--info">
                                <svg class="cn-svg-icon" role="img">
                                    <title>Success Alert</title>
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                                    ></use>
                                </svg>
                            </div>
                            <div class="cn-alert__label">
                                <span>{{ message }}</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
    `,
    styles: `
        s25-ng-dropdown-rate-group {
            width: 20em;
            display: block;
        }

        ul {
            list-style: none;
            padding: 0;
        }

        li {
            display: flex;
            gap: 0.5em;
            margin: 0.5em;
        }

        .rateOptions {
            display: flex;
        }

        ::ng-deep s25-ng-tax-schedules s25-datepicker .form-group > label {
            display: block;
        }

        ::ng-deep s25-ng-tax-schedules s25-ng-editable-percentage > div {
            margin-left: 0.5em;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25TaxSchedulesComponent implements OnInit {
    @Input({ required: true }) tax: DropDownItem;
    @Input() mode: "create" | "copy" | "edit" = "edit";

    @Output() saved = new EventEmitter<void>();
    @Output() cancelled = new EventEmitter<void>();

    model: any = {};
    init = false;
    isDirty = false;
    loading = false;
    newRateGroups: DropDownItem[];
    message = "";
    errMsg = { taxName: "", rates: "", rateGroups: "" };

    constructor(private cd: ChangeDetectorRef) {}

    async ngOnInit() {
        if (!this.tax?.itemId || this.mode === "create") {
            this.model.data = {};
        } else {
            const data = await TaxesService.getTax(S25Util.parseInt(this.tax.itemId));
            this.model.data = data;
            this.model.data.rate = data.rate.map((rate: any) => {
                return {
                    percentage: formatNumber(rate.percentage * 100, "en-US", "1.1-3"),
                    version_nbr: rate.version_nbr,
                    status: rate.status,
                    startDateModel: { date: S25Util.date.parse(rate.start_dt), showToday: true },
                };
            });
            if (this.mode === "copy") {
                this.model.data.tax_id = null; // No ID => create new
                this.model.data.tax_name += " copy";
            }

            this.model.initData = S25Util.deepCopy(this.model.data);
            this.newRateGroups = S25Util.array.forceArray(this.model.data.rate_group).map((rg: any) => ({
                itemId: Number(rg.rate_group_id),
                itemName: rg.rate_group_name,
            }));
        }
        this.done();
    }

    done(): void {
        this.init = true;
        this.isDirty = false;
        this.cd.detectChanges();
    }

    addRate() {
        let today = new Date();
        let dateModel = {
            date: S25Util.date.createDate(today.getFullYear(), today.getMonth() + 1, today.getDate()),
            showToday: true,
        };
        this.model.data.rate = S25Util.array.forceArray(this.model.data.rate);
        this.model.data.rate.push({
            startDateModel: dateModel,
            status: "new",
            percentage: "0",
        });
        this.reset();
        this.cd.detectChanges();
    }

    removeRate(rate: any) {
        rate.status = "del";
        this.reset();
        this.cd.detectChanges();
    }

    onChange(item: any) {
        this.reset();
        if ("est" === item.status) item.status = "mod";
    }

    save() {
        this.loading = true;
        this.cd.detectChanges();

        if (this.validate()) {
            let payload = S25Util.deepCopy(this.model.data);
            payload.rate_group = this.getRateGroupChanges();

            let versions = payload.rate?.map((rate: any) => {
                return S25Util.parseInt(rate.version_nbr || 0);
            });
            let maxVersion = Math.max(...versions) || 1;
            payload.rate = S25Util.array.forceArray(payload.rate);
            payload.rate = payload.rate?.map((rate: any) => {
                let version = rate.version_nbr || ++maxVersion;
                return {
                    percentage: formatNumber(rate.percentage / 100, "en-US", "1.1-5"),
                    version_nbr: S25Util.toStr(version),
                    status: rate.status,
                    start_dt: rate.startDateModel.date
                        ? S25Util.date.toS25ISODateStr(rate.startDateModel.date)
                        : S25Util.date.toS25ISODateStr(rate.startDateModel),
                };
            });

            TaxesService.saveTax(this.model.data.tax_id, payload).then(
                (resp) => {
                    S25Util.setOnObjectDeep(this.model.data, "status", "est");
                    this.loading = false;
                    this.message = "Success!";
                    this.done();
                    this.saved.emit();
                },
                (err) => {
                    S25Util.showError(err);
                    this.loading = false;
                    this.done();
                    this.saved.emit();
                },
            );
        } else {
            this.loading = false;
            this.done();
        }
    }

    reset() {
        this.message = "";
        this.errMsg = { taxName: "", rates: "", rateGroups: "" };
    }

    cancel() {
        this.tax = null;
        this.cd.detectChanges();
        this.cancelled.emit();
    }

    validate() {
        this.reset();
        let isValid = true;
        if (!this.model?.data?.tax_name) {
            this.errMsg.taxName = "Please enter a name";
            isValid = false;
        }
        if (!this.model?.data?.rate || this.model?.data?.rate?.filter((r: any) => r.status !== "del") < 1) {
            this.errMsg.rates = "Please enter at least one tax rate";
            isValid = false;
        }
        return isValid;
    }

    getRateGroupChanges() {
        this.model.data.rate_group = S25Util.array.forceArray(this.model.data.rate_group);
        const oldGroups = new Set(this.model.data.rate_group.map((rg: any) => rg.rate_group_id));
        const newGroups = new Set(this.newRateGroups.map((rg) => String(rg.itemId)));

        const groups = [];
        for (const group of this.model.data.rate_group) {
            const status = newGroups.has(group.rate_group_id) ? group.status : "del";
            groups.push({ ...group, status }); // Make copy
        }
        for (const { itemName, itemId } of this.newRateGroups) {
            if (oldGroups.has(String(itemId))) continue; // Already included
            groups.push({
                rate_group_name: itemName,
                rate_group_id: itemId,
                status: "new",
            });
        }

        return groups;
    }
}
